











































































































import { CarBrand } from '@/api/interfaces/carbrand'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'SelectSearchCarBrand',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      dialogCarBrand: false,
      isSubmiting: false,
      brand: '',
    }
  },
  computed: {
    ...mapState('carBrand', {
      carBrandList: 'list',
      isLoadingCarBrands: 'isLoading',
    }),
  },
  created () {
    this.load()
  },
  methods: {
    ...mapActions('carBrand', {
      load: 'load',
      save: 'save',
    }),
    itemText (item: CarBrand) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      return item.brand
    },
    onInput (uri: string) {
      const carBrand = (this.carBrandList as Array<CarBrand>).find(
        x => x['@id'] === uri,
      )
      this.$emit('input', carBrand)
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    async onSubmit () {
      this.isSubmiting = true
      try {
        const newCarBrand = await this.save(this.brand)
        this.$emit('input', newCarBrand)
        this.clear()
        this.dialogCarBrand = false
      } finally {
        this.isSubmiting = false
      }
    },
    clear () {
      this.brand = ''
      this.getValidationObserver().reset()
    },
    createBrand () {
      this.dialogCarBrand = !this.dialogCarBrand
    },
  },
})
